import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Header } from './components/Header';
import { About } from './components/About';
import { Services } from './components/Services';
import { OurWork } from './components/OurWork';
import { Testimonials } from './components/Testimonials';
import { Awards } from './components/Awards';
import { Contact } from './components/Contact';
import Navbar from './components/Navbar/index';
import Sidebar from './components/SideBar';
import SmoothScroll from 'smooth-scroll'
import JsonData from './data/data.json'
import 'aos/dist/aos.css';
import Aos from 'aos';

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
})

export interface IState {
  Header: {
    title: string,
    paragraph: string
  } | undefined,
  About: {
    title: string,
    paragraph: string,
    paragraph2: string,
    title2: string,
    Why: string[],
    Why2: string[]
  } | undefined,
  Services: {
    icon: string,
    name: string,
    text: string
  }[] | undefined,
  Awards: {
    title: string,
    text: string,
    link: string,
  } | undefined
  Testimonials: {
    img: string,
    text: string,
    name: string
  }[] | undefined,
  Contact: {
    title: string,
    text: string,
    phone: string,
    email: string
  } | undefined
}

function App() {
  const [data, setData] = useState<IState | undefined>(undefined);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setData(JsonData)
    Aos.init({ duration: 1200, ease: 'ease-in-out-sine', delay: 0, once: true });
  }, [])

  return (
    <div className="App" style={{ overflowX: "hidden" }} >
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <Header data={data?.Header} />
      <About data={data?.About} />
      <Services data={data?.Services} />
      <OurWork />
      <Awards data={data?.Awards} />
      <Testimonials data={data?.Testimonials} />
      <Contact data={data?.Contact} />
    </div >
  );
}

export default App;
