
import img1Small from '../img/portfolio/S2.webp'
import img2Small from '../img/portfolio/S1.webp'
import img3Small from '../img/portfolio/S4.webp'
import img4Small from '../img/portfolio/S3.webp'
import img5Small from '../img/portfolio/S5.webp'
import img6Small from '../img/portfolio/S7.webp'
import img7Small from '../img/portfolio/S8.webp'
import img8Small from '../img/portfolio/S9.webp'
import img9Small from '../img/portfolio/S6.webp'
import img10Small from '../img/portfolio/S10.webp'
import img11Small from '../img/portfolio/S11.webp'
import img12Small from '../img/portfolio/S12.webp'
import img13Small from '../img/portfolio/S13.webp'
import img14Small from '../img/portfolio/S14.webp'
import img15Small from '../img/portfolio/S15.webp'
import img16Small from '../img/portfolio/S16.webp'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay, EffectCoverflow } from 'swiper';
import { Tile } from './DumbComponents/Tile'

export const OurWork = () => {

  const IMAGES = [
    { src: img1Small },
    { src: img2Small },
    { src: img3Small },
    { src: img4Small },
    { src: img5Small },
    { src: img6Small },
    { src: img7Small },
    { src: img8Small },
    { src: img9Small },
    { src: img10Small },
    { src: img11Small },
    { src: img12Small },
    { src: img13Small },
    { src: img14Small },
    { src: img15Small },
    { src: img16Small },
  ]
  return (
    <div id='our-work' className='text-center'>
      <div className='container'>
        <div className='section-title' data-aos="fade-right">
          <h2>Our Work</h2>
        </div>

        <Swiper
          modules={[Navigation, Pagination, Autoplay, EffectCoverflow]}
          className={"our-work-images-carousel"}
          slidesPerView={"auto"}
          navigation={true}
          freeMode={true}
          grabCursor={true}
          loop={true}
          centeredSlides={true}
          effect={"coverflow"}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          pagination={{ dynamicBullets: true, }}
          breakpoints={{ 0: { slidesPerView: 1 }, 800: { slidesPerView: 2 } }}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          data-aos="zoom-in-right"
        >
          {IMAGES.map((image, i) => {
            return <SwiperSlide key={i}> <Tile img={image} alt={"fraser valley landscaping"} /> </SwiperSlide>
          })}
        </Swiper>
      </div >
    </div >
  )
}
