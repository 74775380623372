import styled from 'styled-components';

interface Props {
  img: {
    src: string,
  }
  alt: string
}

const Container = styled.div`
  cursor: pointer;
  width: 100%;
  transition: 500ms ease-in-out;
  overflow: hidden;
  display: block;
`;

const Image = styled.img`
  height: 100%; 
  width: 100%;
  transition: 500ms ease-in-out;
  &:hover{
    transform: scale(1.1);
  }
`;

export const Tile: React.FC<Props> = (props) => {

  return (
    <Container>
      <Image
        src={props.img.src}
        alt={props.alt}
        loading="lazy"
      />
    </Container>
  )
}
