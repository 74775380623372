import React, { useEffect, useState } from 'react'
import { CgMenuRight } from 'react-icons/cg'
import { MobileIcon, Nav, NavbarContainer, NavBtn, NavBtnLink, NavItem, NavLinks, NavLogo, NavLogoImage, NavMenu } from './NavbarElements'

import BlackLogo from '../../img/logo/green-black-1line.svg'
import WhiteLogo from '../../img/logo/white-1line.svg'

interface Props {
  toggle: React.MouseEventHandler<HTMLDivElement> | undefined
}

const Navbar: React.FC<Props> = (props) => {
  const [scrollNav, setScrollNav] = useState(false);
  const changeNav = () => {
    (window.scrollY >= 80) ? setScrollNav(true) : setScrollNav(false);
  }

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
  }, []);
  return (
    <>

      <Nav scrollNav={scrollNav}>
        <NavbarContainer>
          <NavLogo href="#header" scrollNav={scrollNav}>
            <NavLogoImage src={(scrollNav ? BlackLogo : WhiteLogo)} alt="Able Boys Landscaping"></NavLogoImage>
          </NavLogo>

          <MobileIcon onClick={props.toggle} scrollNav={scrollNav}>
            <CgMenuRight />
          </MobileIcon>
          <NavMenu items={['header', 'about', 'services', 'our-work', 'testimonials']} currentClassName="is-active" offset={-20}>
            <NavItem style={{ display: 'none' }}>
              <NavLinks scrollNav={scrollNav} href="#header" style={{ display: 'none' }}>Home</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks scrollNav={scrollNav} href="#about">About Us</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks scrollNav={scrollNav} href="#services">Services</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks scrollNav={scrollNav} href="#our-work">Our Work</NavLinks>
            </NavItem>
            {/* <NavItem>
              <NavLinks scrollNav={scrollNav} href="#awards">Awards</NavLinks>
            </NavItem> */}
            <NavItem>
              <NavLinks scrollNav={scrollNav} href="#testimonials">Testimonials</NavLinks>
            </NavItem>
          </NavMenu>
          <NavBtn>
            <NavBtnLink href="#contact" scrollNav={scrollNav}>Contact</NavBtnLink>
          </NavBtn>
        </NavbarContainer>
      </Nav>
    </>
  )
}

export default Navbar;