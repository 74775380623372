import styled from "styled-components";

interface Props {
  data: {
    icon: string,
    name: string,
    text: string
  }
}

const Image = styled.i`
  font-size: 46px;
  width: 120px;
  height: 120px;
  padding: 38px 0;
  background: #fff;
  border-radius: 50%;
  color: #0e7645;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, .05);
`;
const Title = styled.h3`
  font-weight: 500;
  padding: 5px 0;
  color: #fff;
`;

const Desc = styled.h6`
  color: rgba(255, 255, 255, .75);
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 1rem;
  line-height: inherit;
`;

const TextSection = styled.div`
  margin: 10px 10px 40px;
`;

export const Service: React.FC<Props> = (props) => {
  return (
    <div className='col-md-3 col-sm-6' >
      <Image className={props.data.icon} data-aos="zoom-in" />
      <TextSection data-aos="zoom-in" data-aos-delay="100">
        <Title>{props.data.name}</Title>
        <Desc>{props.data.text}</Desc>
      </TextSection>
    </div>
  )
}
