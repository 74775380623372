import styled from 'styled-components';
import { IState as IProps } from '../../App';

interface Props {
  data: IProps["Contact"];
}

const InfoTitle = styled.h3`
  color: #fff;
  margin-top: 80px;
  margin-bottom: 25px;
  padding-bottom: 20px;
  font-weight: 400;
`;

const InfoContainer = styled.div`  
  margin: 20px 0;
`;
const ImageContainer = styled.span`
  color: rgba(255, 255, 255, 1);
  margin-bottom: 10px;
  display: block;
`;
const Logo = styled.i`
  margin-right: 10px;
`;
const Link = styled.a`
  color: inherit;
  &:hover{
    color:inherit;
  }
`;


export const ContactInfo: React.FC<Props> = (props) => {
  return (
    <div className='col-md-4 offset-md-1 contact-info col-lg-3 offset-lg-1' data-aos="fade-right" >
      <InfoContainer>
        <InfoTitle>Contact Info</InfoTitle>
        <p>
          <ImageContainer>
            <Logo className='fa fa-phone' /> Phone
          </ImageContainer>
          <Link href={`tel:${props.data === undefined ? '' : props.data.phone}`} >(604) 377-3107</Link>
        </p>
      </InfoContainer>
      <InfoContainer>
        <p>
          <ImageContainer>
            <Logo className='fa fa-envelope-o' /> Email
          </ImageContainer>
          <Link href={`mailto:${props.data === undefined ? '' : props.data.email}`}>
            {props.data === undefined ? '' : props.data.email}
          </Link>
        </p>
      </InfoContainer>
    </div>
  )
}
