import { IState as IProps } from '../App';
import styled from 'styled-components';
import background from '../img/into-bg.webp'

interface Props {
  data: IProps["Header"];
}

const Intro = styled.div`
  display: table;
  width: 100%;
  padding: 0;
  background: url(${background}) center center no-repeat;
  background-color: #e5e5e5;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
`;

const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.35);
`;

const Title = styled.h1`
  font-family: 'Raleway', sans-serif;
  color: #fff;
  font-size: 82px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    font-size: 62px !important;
  }
  @media (max-width: 450px) {
    font-size: 40px !important;
  }
`;

const SubTitle = styled.h4`
  color: #fff;
  font-size: 25px;
  font-weight: 400 !important;
  line-height: 30px;
  margin: 0 auto;
  margin-bottom: 60px;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const TextContainer = styled.div`
  padding-top: 300px;
  padding-bottom: 220px;
  text-align: center;
  @media (max-width: 768px) {
    padding-top: 250px;
    padding-bottom: 120px;
    text-align: center;
  }
`;



export const Header: React.FC<Props> = (props) => {

  return (
    <section id='header'>
      <Intro>
        <Overlay>
          <div className='container'>
            <div className='row'>
              <TextContainer className='col-md-10 col-md-offset-1 col-sm-12'>
                <Title data-aos="fade-down">
                  {props.data === undefined ? 'Loading' : props.data.title}
                </Title>
                <SubTitle data-aos="fade-up" data-aos-delay="600">{props.data === undefined ? 'Loading' : props.data.paragraph}</SubTitle>
                <div className="arrow-container" data-aos="fade-in" data-aos-delay="1200">
                  <a data-scroll href="#about">
                    <div className="arrow"></div>
                  </a>
                </div>
              </TextContainer>
            </div>
          </div>
        </Overlay>
      </Intro>
    </section>
  );
}


