import React from 'react'
import { CloseIcon, Icon, SidebarBtn, SidebarContainer, SidebarLink, SidebarMenu, SidebarWrapper, SideBtnWrap } from './SidebarElements'


interface Props {
  toggle: React.MouseEventHandler<HTMLDivElement> | undefined,
  isOpen: boolean

}

const Sidebar: React.FC<Props> = (props) => {
  return (
    <>
      <SidebarContainer isOpen={props.isOpen} onClick={props.toggle}>
        <Icon>
          <CloseIcon />
        </Icon>
        <SidebarWrapper>
          <SidebarMenu>
            <SidebarLink href='#about'>About Us</SidebarLink>
            <SidebarLink href='#services'>Services</SidebarLink>
            <SidebarLink href='#our-work'>Our Work</SidebarLink>
            {/* <SidebarLink href='#awards'>Awards</SidebarLink> */}
            <SidebarLink href='#testimonials'>Testimonials</SidebarLink>
          </SidebarMenu>
          <SideBtnWrap>
            <SidebarBtn href='#contact'>Contact </SidebarBtn>
          </SideBtnWrap>
        </SidebarWrapper>
      </SidebarContainer>
    </>
  )
}

export default Sidebar
