import { useState } from 'react'
import emailjs from 'emailjs-com'
import styled from 'styled-components';
import { IState as IProps } from '../App';
import { ContactInfo } from './DumbComponents/ContactInfo';

interface Props {
  data: IProps["Contact"];
}

const Section = styled.section`
  padding: 100px 0 60px 0;
  /* background: linear-gradient(to right, #1d976c 0%, #93f9b9 100%);
   */
  /* background: linear-gradient(to right, #1e5b53 0%, #BCF0A2 100%); */
  background: linear-gradient(110deg, #0a4723 0%, #0e7645 20%, #a8dc8e 100%);

  color: rgba(255, 255, 255, .75);
`;
const TitleSection = styled.div`
  margin-bottom: 40px;
  width: 100%;
`;
const Spread = styled.div` 
  margin: 0 !important;
`;
const Title = styled.h2`  
  color: #fff;
  &:after {
    background: rgba(255, 255, 255, .3);
    left: 30px;
  }
`;
const SubTitle = styled.p`  
  font-size: 16px;
`;
const Form = styled.form`
  padding-top: 20px;
  width: 100%;
`;
const Container = styled.div`  
  display: flex;
`;
const Error = styled.p`
  color: #cc0033;
  text-align: left;
`;

const Button = styled.button`
  margin: 30px 0;
  border: 2px solid #fff;
  font-family: 'Raleway', sans-serif;
  text-transform: uppercase;
  padding: 14px 34px !important;
  letter-spacing: 1px;
  font-size: 15px !important;
  font-weight: 500 !important;
  border-radius: 25px !important;
  transition: all 0.5s linear !important;
  /* color: #3DB281 ; */
  color: #0e7645 ;
  
  background: #fff;
  &:hover {
  color: #fff ;
  background: transparent ;
  }
`;

const Input = styled.input`
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.42857143;
  color: #444;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ddd;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
  &:focus {
    border-color: #999;
    outline: 0;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  &:-webkit-input-placeholder {
    color: #777;
  }
  &:-moz-placeholder {
    color: #777;
  }
  &:-ms-input-placeholder {
    color: #777;
  }
`;

export const Contact: React.FC<Props> = (props) => {
  const blankInput = { name: '', email: '', message: '' };

  const [input, setInput] = useState(blankInput);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value
    })
  }

  const clearState = () => setInput(blankInput);

  const sendEmail = (e: any) => {
    e.preventDefault()
    const SERVICE_ID: string = process.env.REACT_APP_EMAILJS_SERVICE_ID!;
    const TEMPLATE_ID: string = process.env.REACT_APP_EMAILJS_TEMPLATE_ID!;
    const USER_ID: string = process.env.REACT_APP_EMAILJS_USER_ID!;


    emailjs.sendForm(
      SERVICE_ID, TEMPLATE_ID, e.target, USER_ID
    ).then(
      (result) => {
        clearState()
      },
      (error) => {
      }
    )
  }

  return (
    <Section id='contact'>
      <Container className='container'>
        <Spread className='row spread'>
          <div className='col-md-7  col-lg-8'>
            <div className='row'>
              <TitleSection data-aos="fade-right">
                <Title>
                  {props.data === undefined ? 'Loading' : props.data.title}
                </Title>
                <SubTitle>
                  {props.data === undefined ? 'Loading' : props.data.text}
                </SubTitle>
              </TitleSection>
              <Form name='sentMessage' className='form' onSubmit={(sendEmail)} data-aos="fade-right">
                <div className='row' >
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <Input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='Name'
                        required
                        value={input.name}
                        onChange={handleChange}
                      />
                      <Error className='help-block text-danger'></Error>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <Input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Email'
                        required
                        value={input.email}
                        onChange={handleChange}
                      />
                      <Error className='help-block text-danger'></Error>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows={4}
                    placeholder='Message'
                    required
                    onChange={handleChange}
                    value={input.message}
                  ></textarea>
                  <Error className='help-block text-danger'></Error>
                </div>
                <div id='success'></div>
                <Button type='submit' className='btn btn-custom btn-md'>
                  Send Message
                </Button>
              </Form>
            </div>
          </div>
          <ContactInfo data={props.data} />
        </Spread>
      </Container>
    </Section>
  )
}
