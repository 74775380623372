import styled from "styled-components";
interface Props {
  review: {
    img: string,
    text: string,
    name: string
  }
}
const Review = styled.div`
  position: relative;
  padding: 20px;
`;
const DefaultLogo = styled.i`
  color: white;
  margin-bottom: 20px;
  width: inherit;
  height: inherit;
  font-size: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, #0a4723 0%, #0e7645 20%, #a8dc8e 100%);
`;
const Image = styled.img`
  display: block;
  width: 64px;
  height: 64px;
  border-radius: 50%;
`;
const Container = styled.div`
  display: block;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  float: left;
  margin-right: 15px;
`;
const Content = styled.section`
  position: relative;
  overflow: hidden;
`;
const Text = styled.section`
  margin-bottom: 0;
  font-size: 14px;
  font-style: italic;
`;

const Name = styled.section`
  margin-top: 10px;
  font-size: 15px;
  font-weight: 600;
  color: #666;
`;


export const Testimonial: React.FC<Props> = (props) => {
  return (
    <div>
      <Review>
        <Container >
          {props.review.img === '' ?
            <DefaultLogo className="fa user" /> :
            <Image src={`${process.env.PUBLIC_URL}/${props.review.img}`} loading="lazy" alt='review' />
          }

        </Container>
        <Content >
          <Text >{props.review.text}</Text>
          <Name className='testimonial-meta' > - {props.review.name} </Name>
        </Content>
      </Review>
    </div>

  )
}
