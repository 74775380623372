import styled from 'styled-components';
import { CgClose } from 'react-icons/cg';

interface Props {
  isOpen: boolean;
}

export const SidebarContainer = styled.aside<Props>`
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: linear-gradient(110deg, #0a4723 0%, #0e7645 20%, #a8dc8e 100%);

  display: grid;
  align-items: center;
  left: 0;
  transition: 0.5s ease-in-out;
  opacity: ${props => props.isOpen ? '100%' : '0'};
  top: ${props => props.isOpen ? '0' : '-140%'};
`;

export const CloseIcon = styled(CgClose)`
  color: #fff
  `;

export const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`;

export const SidebarWrapper = styled.div`
  color: #fff;
`;

export const SidebarLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  list-style: none;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;

  &:hover, &:active{
    color: white;
    text-decoration: none;
  }
  &:after{
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background: #fff;
    content: "";
    transition: width 0.5s;
  }
   &:hover:after{
    width: 100%;
  }
`;

export const SideBtnWrap = styled.div`
  display: flex;
  justify-content: center;
`;

export const SidebarBtn = styled.a`
  white-space: nowrap;
  outline: none;
  background: transparent;
  border: 2px solid #fff;
  font-family: 'Raleway', sans-serif;
  text-transform: uppercase;
  color: #fff ;
  padding: 14px 34px;
  letter-spacing: 1px;
  font-size: 15px;
  font-weight: 500;
  border-radius: 25px !important;
  transition: all 0.5s linear !important;
  &:hover{
    color: #5D9773 !important;
    background: #fff;
    text-decoration: none;
  }
`;

export const SidebarMenu = styled.div`
  display: grid;
  padding: 2rem;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, 80px);
  text-align: center;
  @media screen and (max-width: 480px){
    grid-template-rows: repeat(4, 60px);
  }
`;