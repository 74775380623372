import { IState as IProps } from '../App';
import { Service } from './DumbComponents/Service'
import styled from 'styled-components';


interface Props {
  data: IProps["Services"];
}

const Section = styled.section`
  padding: 100px 0;
  background: linear-gradient(110deg, #0a4723 0%, #0e7645 20%, #a8dc8e 100%);
  color: #fff;
  text-align: center;
`;
const Title = styled.h2`
  color: #fff;
  &:after {
    background: rgba(255, 255, 255, .3);
    margin-left: -30px;
    left: 50%;
  }
`;

export const Services: React.FC<Props> = (props) => {
  return (
    <Section id='services'>
      <div className='container'>
        <div className='section-title' data-aos="fade-right">
          <Title>Our Services</Title>
          <p>Reimagine your existing outdoor living space.</p>
        </div>

        <div className='row'>
          {props.data === undefined ? 'Loading' : props.data.map((service, index) => {
            return <Service data={service} key={index} />
          })}
        </div>
      </div>
    </Section>
  )
}
