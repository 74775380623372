import styled from 'styled-components';
import { IState as IProps } from '../App';
import { Testimonial } from './DumbComponents/Testimonial';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper';

import 'swiper/swiper-bundle.css';

interface Props {
  data: IProps["Testimonials"];
}

const Section = styled.section`
  padding: 100px 0;
  background: #fff;
  /* background: #f6f6f6; */
`;

export const Testimonials: React.FC<Props> = (props) => {
  return (
    <Section id='testimonials'>
      <div className='container'>
        <div className='section-title text-center' data-aos="fade-left">
          <h2>What our clients say</h2>
        </div>
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          spaceBetween={30}
          slidesPerView={3}
          navigation={true}
          freeMode={true}
          grabCursor={true}
          autoplay={{ delay: 10000, disableOnInteraction: false }}
          pagination={{ dynamicBullets: true, }}
          breakpoints={{
            0: { slidesPerView: 1 }, 550: { slidesPerView: 2 }, 950: { slidesPerView: 3 }
          }}
          data-aos="zoom-in"
        >
          {props.data === undefined ? '' : props.data.map((review, i) => {
            return <SwiperSlide key={i}><Testimonial review={review} /></SwiperSlide>
          })}
        </Swiper>
      </div>
    </Section>
  )
}
