// import aboutImg from '../img/about.jpg'
import { IState as IProps } from '../App';
import styled from 'styled-components';
import checkmarkIcon from '../img/services/check-solid.svg';

interface Props {
  data: IProps["About"];
}

const AboutUs = styled.div`
  padding: 100px 0;
  text-align: center;
`;
// const ImageContainer = styled.img`
//   width: 520px;
//   margin-top: 10px;
//   background: #fff;
//   border-right: 0;
//   box-shadow: 0 0 50px rgba(0, 0, 0, 0.06);
//   display: block;
//   max-width: 100%;
//   height: auto;
//   @media (max-width: 768px) {
//     margin: 50px 0 ;
//   }
// `;
const Title = styled.h2`
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 15px;
  &:after {
    position: absolute;
    content: "";
    background: linear-gradient(110deg,  #a8dc8e 0%,#0e7645 80%, #0a4723 100%);
    height: 4px;
    width: 60px;
    bottom: 0;
    left: 50%;
    margin-left: -30px;
  }
`;
const SubTitle = styled.h3`
  font-size: 22px;
  margin: 0 0 20px 0;
`;
const Info = styled.h6`
  line-height: 24px;
  margin: 15px 0;
`;
const List = styled.ul`
  margin: 0;
`;

// const Items = styled.li`
//   margin-bottom: 6px;
//   margin-left: 6px;
//   list-style: none;
//   padding: 0;
//   &:before {
//     content: '\f00c';
//     color: #5D9773;
//     font-size: 11px;
//     font-weight: 300;
//     padding-right: 8px;
//   }
// `;

const Items = styled.div`
  margin-bottom: 6px;
  list-style: none;
  padding: 0;
  display: flex; /* Align icon and text horizontally */
  align-items: center; /* Center vertically */
  text-align: left;

`;

const Icon = styled.div`
  width: 16px !important; /* Adjust the width as needed */
  height: 16px !important; /* Adjust the height as needed */
  background-image: url(${checkmarkIcon});
  background-size: cover;
  margin-top: 3px;
  padding-right: 16px;
  margin-right: 16px;
`;

export const About: React.FC<Props> = (props) => {
  return (
    <AboutUs id='about'>
      <div className='container'>
        <div className='row'>
          {/* <div className='col-xs-12 col-md-6' data-aos="zoom-in" >
            <ImageContainer src={aboutImg} alt='' />
          </div> */}
          <div className='col-xs-12 col-md-12'>
            <div className='about-text' >
              <Title data-aos="fade-left">
                {props.data === undefined ? 'Loading' : props.data.title}
              </Title>
              <div data-aos="fade-left" data-aos-delay="300">
                <Info >
                  {props.data === undefined ? 'Loading' : props.data.paragraph}
                </Info>
                <Info >
                  {props.data === undefined ? 'Loading' : props.data.paragraph2}
                </Info>
              </div>
              <SubTitle data-aos="fade-left" data-aos-delay="600">
                {props.data === undefined ? 'Loading' : props.data.title2}
              </SubTitle>
              <div className='row' data-aos="fade-left" data-aos-delay="900">
                <List className='col-md-6'>
                  {props.data === undefined ? '' : props.data.Why.map((item, i) => { return <Items key={i}> <Icon />{item}</Items> })}
                </List>
                <List className='col-md-6'>
                  {props.data === undefined ? '' : props.data.Why2.map((item, i) => { return <Items key={i}> <Icon />{item}</Items> })}
                </List>
              </div>

            </div>
          </div>
        </div>
      </div>
      {/* <Image >
        <BsArrowUpShort />
      </Image> */}
    </AboutUs>

  )
}
