import styled from 'styled-components';
import Scrollspy from 'react-scrollspy'

interface Props {
  scrollNav: boolean
}

export const Nav = styled.nav<Props>`
  background: ${props => props.scrollNav ? '#fff' : 'transparent'};
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: fixed;
  top: 0;
  z-index: 999;
  box-shadow: ${props => props.scrollNav ? '0 2px 4px 0 rgba(0,0,0,.2)' : 'none'};

  @media screen and (max-width: 970px){
    transition: 0.3s all ease;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1300px;
`;

export const NavLogo = styled.a<Props>`
  color: ${props => props.scrollNav ? '#000' : '#fff'};
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  font-weight: bold;
  text-decoration: none;
`;

export const NavLogoImage = styled.img`
  height: 80px;
  padding: 13px 0px;
  @media screen and (max-width: 1048px){
    padding: 16px 0px;
  }
  @media screen and (max-width: 970px){
    padding: 13px 0px;
  }
  @media screen and (max-width: 480px){
    padding: 16px 0px;
  }
  @media screen and (max-width: 400px){
    padding: 20px 0px;
  }
`;

export const MobileIcon = styled.div<Props>`
  display: none;

  @media screen and (max-width: 970px){
    color: ${props => props.scrollNav ? '#000' : '#fff'};
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 24px;
    font-size: 1.8rem;
    cursor: pointer;
    line-height: 80px;
    height: inherit;
  }
`;

export const NavMenu = styled(Scrollspy)`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: -22px;
  margin: 0!important;
  @media screen and (max-width: 970px){
    display: none;
  }
`;

export const NavItem = styled.li`
  height: 80px;
`;

export const NavLinks = styled.a<Props>`
  color: ${props => props.scrollNav ? '#555' : '#fff'};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 400;
  border-radius: 0;
  position: relative;

  &:after{
    display: block;
    position: absolute;
    left: 0;
    bottom: 15px;
    width: 0;
    height: 2px;
    background: ${props => props.scrollNav ? 'linear-gradient(110deg, #0a4723 0%, #0e7645 20%, #a8dc8e 100%)' : '#fff'};
    content: "";
    transition: width 0.5s;
  }
  &:hover::after{
    width: 100% !important;
  }
  &:hover{
    text-decoration: none;
    color: ${props => props.scrollNav ? '#555' : '#fff'};
  }
  &:focus{
    text-decoration: none;
    color: ${props => props.scrollNav ? '#555' : '#fff'};
  }
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  @media screen and (max-width:970px){
    display: none;
  }
`;

export const NavBtnLink = styled.a<Props>`
  background: ${props => props.scrollNav ? 'linear-gradient(110deg, #0a4723 0%, #0e7645 20%, #a8dc8e 100%)' : 'transparent'};

  white-space: nowrap;
  padding: 10px 22px;
  color: #fff;
  outline: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff ;
  letter-spacing: 1px;
  font-size: 15px;
  font-weight: 500;
  border-radius: 25px ;
  border: #fff 2px solid;
  transition: all 0.5s linear ;
  &:hover{
    transition: all 0.5s linear ;
    background: transparent;
    color: #5D9773;
    text-decoration: none;
    border: ${props => props.scrollNav ? '#5D9773 2px solid' : '#fff 2px solid'};
    background-color: #fff;
  }

  @media screen and (max-width:970px){
    display: none;
  }
`;