

import styled from "styled-components";
import { IState as IProps } from '../App';
import awardImg from '../img/awards/Vancouver.webp'

const Section = styled.section`
  padding: 100px 0;
  background: #f6f6f6;
`;

const Image = styled.img`
  font-size: 46px;
  max-width: 240px !important;
  max-height: 240px !important;
  display: block;
  border-radius: 50%;
`;
const Title = styled.h3`
  font-weight: 500;
  padding: 5px 0;
`;

const Desc = styled.h4`
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 1rem;
  line-height: inherit;
  text-transform: none;
`;

const TextSection = styled.div`
  margin: 10px 10px 40px;
`;

interface Props {
  data: IProps["Awards"];
}

export const Awards: React.FC<Props> = (props) => {

  return (
    <Section id='awards' className='text-center'>
      <div className='container'>
        <div className='section-title text-center' data-aos="fade-right">
          <h2>Awards</h2>
        </div>
        <div className='row justify-content-center' data-aos="fade-right">
          <a href={props.data?.link} className="col-sm-12 col-md-4 row justify-content-center">
            <Image src={awardImg} data-aos="zoom-in" loading="lazy" alt="award" />
          </a>
          <TextSection className='col-sm-12 col-md-8' data-aos="fade-right">
            <a href={props.data?.link}><Title> {props.data?.title}</Title></a>
            <Desc>{props.data?.text}</Desc>
          </TextSection>
        </div>
      </div >
    </Section >
  )
}
